@font-face {
  font-family: 'Open Sans Bold';
  src: url('./OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('./OpenSans-Regular.ttf') format('truetype');
}


@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('./OpenSans-SemiBold.ttf') format('truetype');
}

