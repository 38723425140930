@import './fonts/open-sans/OpenSans.css';
@import './fonts/Inter/Inter.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
textarea,
span {
  font-family: 'Open Sans Regular';
}
a {
  text-decoration: none;
}
.reactEasyCrop_CropArea {
  width: 260px !important;
  height: 260px !important;
}

.email-link {
  text-decoration: underline;
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  @media (max-width: 750px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.email-link-dark {
  text-decoration: underline;
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #183561;
  @media (max-width: 750px) {
    font-size: 16px;
    line-height: 24px;
  }
}


::-webkit-scrollbar {
  width: 6px !important;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  height: 0;
  background-color: transparent;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-thumb:vertical {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  height: 1px;
  display: block;
  background-color: transparent;
}
