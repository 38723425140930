.upload_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 16px 24px;
  width: 100%;
  height: 128px;
  background: #f5f5f5;
  border: 1px dashed #e4e7ec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #0b323e;
  @media  (max-width: 750px) {
    height: 100%;
  }
}

.btn-warning {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: #f5f5f5;
  color: #0f6fd9;
  position: relative;
  padding: 11px 16px;
  font-size: 15px;
  border: 0;
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;
  @media  (max-width: 750px) {
    padding: 0;
  }
}

.btn-warning input[type='file'].input_file {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}
