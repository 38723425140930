
.triangle {
  position: absolute;
  width: 31px;
  height: 24px;
  top: -20px;
  left: 40%;
}

.not_visible {
  position: absolute;
  cursor: pointer;
  top:0;
  opacity: 0;
  width: 200px;
  height: 100px;
  z-index: -1;
}

.nav__listitem {
  list-style: none;
  position: relative;
  transition: all 400ms ease;
  padding: 0.3rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  z-index: 1000;
}


.nav__listitem:hover::after,
.nav__listitem:focus::after {
  width: 80%;
}

.nav__listitem:hover ul,
.nav__listitem:focus ul {
  opacity: 1;
  visibility: visible;
  
}

.nav__listitemdrop {
  position: absolute;
  top: 4.5rem;
  left: -3.5rem;
  background: #ffffff;
  width: 16rem;
  padding: 10px 0.5rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop li {
  list-style: none;
  border-radius: 0.2rem;
  padding: 10px 0.5rem;
  transition: background-color 200ms ease-in-out;
}

.nav__listitemdrop_services_focus {
  position: absolute;
  top: 4.5rem;
  left: -3.5rem;
  background: #ffffff;
  width: 16rem;
  padding: 10px 0.5rem;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.25);
  border-radius: 23px;
}

.nav__listitemdrop_services_focus li {
  list-style: none;
  padding: 10px 0.5rem;
  border-radius: 0.2rem;
  transition: background-color 200ms ease-in-out;
}

.nav__listitem a {
  color: #183561;
  transition: all 400ms ease;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Open Sans Regular'

}

.nav__listitem a:hover {
  color: #3E13AF ;
  cursor: pointer;
}

.span_nav_item {
  color: white;
  transition: all 400ms ease;
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.span_nav_item:hover {
  color:#F4CD00
}