@font-face {
  font-family: 'Inter Regular';
  src: url('../Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter Medium';
  src: url('../Inter/Inter-Bold.ttf') format('truetype');
}

